.icon-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--gray-500);
  gap: 6px;
  cursor: pointer;

  & span {
    text-decoration: none;
    &:focus-visible {
      outline: none;
    }
  }
}
