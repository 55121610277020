.controlledFileUpload {
  padding: 27px 17px;

  input {
    padding: 0 !important;
  }
  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-size: 16px;
    }
  }
  &__fileInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }
  &__fileStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  &__fileTitle {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: var(--gray-600);
    }
  }

  &__errorMessage {
    color: var(--red-500);
    text-align: start;
  }
  &__error {
    border-color: var(--red-500);
  }
  &__delete {
    color: var(--gray-600);
  }
  &__textWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__pseudoLink,
  &__text {
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 400 !important;
  }
  &__pseudoLink {
    color: var(--primary);
    border-bottom: 1px dashed var(--primary);
    cursor: pointer;
    &:hover {
      border-bottom-color: transparent;
    }
  }

  &__subText {
    color: var(--gray-600);
    line-height: 18px;
    font-weight: 400;
    font-size: 12px;
  }
}
