.title {
  grid-area: title;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  &__name {
    display: flex;
    gap: 10px;
  }
  &__indicator {
    margin-top: 6px;
  }
  span {
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    color: var(--gray-800);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
  &__favoriteIconButton {
    padding: 0 !important;
    align-self: flex-start;
    margin: 0;
    &:before {
      border: 0 !important;
    }
    &:global(.button-cmp) {
      &:before {
        $size: 16px;
        top: -$size/2;
        left: -$size/2;
        width: calc(100% + $size);
        height: calc(100% + $size);
      }
    }
    :global(.justui__icon-wrapper) {
      color: var(--primary);
    }
  }
}
