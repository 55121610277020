.servicePage {
  &__wrapper {
    max-width: 1280px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 32px;
    background: var(--white);
    width: 100%;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__leftSide {
    // height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 8px;
    // max-width: 959px;
    max-width: 100%;
  }
  &__rightSide {
    height: 100%;
    padding: 66px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 800px) {
      padding: 20px 30px 20px;
      > * {
        max-width: 100%;
      }
    }
  }
  &__elementsContainer {
    padding: 0 32px;
  }
  &__header {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}
