.selectFilterModal {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 9px 16px;
  &__buttonsContainer {
    margin-top: 15px;
    display: flex;
    gap: 10px;
  }
  &__selectList {
    :global(.relativeWrapper),
    :global(.scrollbar-container) {
      max-height: 190px !important;
    }
  }
}
