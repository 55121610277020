.filtersContainer {
  display: contents;
  width: 100%;
  height: 48px;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  :global(.FilterModal) {
    &:global(.select) {
      @media (max-width: 650px) {
        min-width: 218px;
      }
    }
    :global(.popper-wrapper) {
      overflow: visible !important;
      @media (max-width: 420px) {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
}
