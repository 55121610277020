.recordTestWidgetHeader {
  display: flex;
  gap: 8px;
  margin-bottom: 17px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  span {
    font-size: 12px;
  }
  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      line-height: 22px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
