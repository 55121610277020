.widgetStub {
  display: flex;
  margin-top: 25px;
  padding: 10px;
  gap: 10px;
  border-radius: 6px;
  align-items: center;
  width: 100%;
  font-size: 16px;
}
