.hidden {
  display: none !important;
}

.paginate {
  margin-bottom: 0;
  margin-top: 24px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  & > * {
    display: flex;
  }

  .paginate_page,
  .paginate_previous,
  .paginate_next {
    height: 30px;
    width: min-content;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    color: var(--primary);

    &:active {
      outline: none;
    }

    &.disabled {
      path {
        fill: #ccc;
      }
    }

    &_link {
      padding: 5px 9px;
      width: 100%;
      height: 100%;
      text-align: center;
      display: block;
      line-height: 22px;
    }

    &-link {
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: min-content;

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.paginate_active {
      background-color: var(--primary);
      border-radius: 8px;
      color: var(--white);
    }
  }
  .paginate_previous,
  .paginate_next {
    &:global(.disabled) {
      color: var(--gray-300);
    }
  }
  .paginate_setPage_input {
    max-width: 3rem;
  }
}
