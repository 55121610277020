.serviceStatusBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  // padding-left: 10px;
  // padding-right: 10px;
  max-width: 688px !important;
  width: 100%;

  &__indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__indicatorLabel {
    color: var(--gray-600);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}
