.testingForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  &__divider {
    padding: 2px 0;
  }
  > * {
    width: 100%;
  }
  > h3 {
    padding-top: 12px;
    &:first-of-type {
      padding-top: 0px;
    }
  }
  &__nameInput {
    margin-bottom: 16.5px;
  }
}
