.iconSelect {
  &__container {
    position: relative;
    height: min-content;
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    z-index: 100;
    overflow: hidden;
  }
}
