.footer {
  grid-area: footer;
  color: var(--gray-500);
  display: flex;
  gap: 22px;
  justify-content: space-between;
  align-items: center;
  &__leftCorner {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    :global(.justui__icon-wrapper) {
      display: flex;
    }
  }
  & svg {
    margin-right: 4px;
  }
}
