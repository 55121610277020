.language,
.textTask,
.voiceTask {
  & > * {
    filter: brightness(0.32);
  }
}
.language {
  background-color: var(--blue-200);
  border-color: var(--blue-200);
}

.voiceTask {
  background-color: var(--yellow-200);
  border-color: var(--yellow-200);
}
.textTask {
  background-color: var(--primary-200);
  border-color: var(--primary-200);
}
.availableInJaicp {
  background-color: transparent;
  border: 1px solid var(--primary-600);
  color: var(--primary-600);
}
