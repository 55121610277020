@mixin testingFormLayout($wrap, $content) {
  .#{$wrap} {
    min-width: 389px;
    max-width: 389px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_fullScreen {
      @extend .#{$wrap};
      max-width: unset;
      display: flex;
      align-items: center;
    }
  }
  .#{$content} {
    position: relative;
    border-radius: 8px;
    background-color: var(--blue-100);
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;
    max-width: 688px;
    box-sizing: content-box;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 8px;
    > * {
      width: 100%;
    }
    input {
      background-color: white !important;
    }
  }
}
