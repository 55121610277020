.predictResult {
  &__responseHead {
    display: flex;
    > h3 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & :global(.justui_spoiler-header-wrap) {
      padding-bottom: 5px;
    }
    & :globall(.justui_spoiler-header) {
      display: flex;
      width: fit-content;
    }
    & :globall(.justui_spoiler-collapse) {
      padding-top: 13px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 6px;
    padding: 12px;
  }
}
