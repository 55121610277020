.predict-result-json-view {
  white-space: pre-wrap;
  & .justui_collapse__body {
    min-height: 0;
  }

  & .token {
    &.punctuation,
    &.operator {
      color: var(--blue-500);
    }
    &.property {
      color: rgba(255, 94, 94, 1);
    }
    &.number,
    &.string,
    &.boolean,
    &.null.keyword {
      color: var(--black);
    }
  }
  &.error {
    & .token {
      &.property {
        color: var(--red-800);
      }
      &.number,
      &.string,
      &.boolean,
      &.null.keyword {
        color: var(--red-600);
      }
    }
  }
}

.justui_spoiler-header-wrap {
  display: flex;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  .justui_spoiler-header {
    flex-flow: row-reverse;
    align-items: flex-end !important;
    gap: 10px;
    & :global(.justui__icon-wrapper) {
      display: flex !important;
    }
    > h6 {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
}
.collapse,
.collapsing {
  width: 100% !important;
}
