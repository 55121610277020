@use 'scss/mixins/custom';

.result {
  &__wrap {
    background: white;
    display: flex;
    border-radius: 6px;
    padding: 8px 12px;
    min-height: 120px;
    max-height: 200px;
    overflow-y: auto;
    @include custom.scrollbar;
    span {
      white-space: pre-line;
    }
  }
}
