.headerAccount {
  position: relative;
  z-index: 755;

  &__organization {
    @media (max-width: 620px) {
      display: none !important;
    }
  }
  :global(.justui_dropdown):global(.show) {
    .headerAccount__accountSelector {
      color: var(--primary);

      h1 {
        color: var(--primary);
      }
    }
  }
  &__accountSelector {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: var(--primary);

      h1 {
        color: var(--primary);
      }
    }

    h1 {
      margin-right: 8px;
      max-width: 208px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-menu {
      button {
        max-width: 232px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-scroll {
        max-height: 370px;
        overflow: auto;
      }
    }
  }
}
