.chip {
  display: flex;
  height: min-content;
  width: min-content;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid transparent;
  &__clickable {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    transition: opacity 0.15s;
  }

  & svg {
    margin-right: 4px;
  }
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
