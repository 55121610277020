.recorder {
  &__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: min-content;
    border: 1px dashed var(--primary-600);
    border-radius: 6px;
    background-color: white;
    padding: 24px 16px;
    :global(.justui__icon-wrapper) {
      display: flex;
    }
    &_player {
      justify-content: center;
      gap: 0px;
    }
  }
  &__recordButton {
    color: var(--gray-600);
    &::before {
      border: transparent !important;
    }
    &_recording {
      @extend .recorder__recordButton;
      color: var(--primary);
    }
  }
  &__waveformContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    :global(#waveform) {
      margin: 0 auto;
      overflow: hidden;
      width: 100%;
      height: min-content;
    }
  }
}
