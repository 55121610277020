.search {
  > :global(.input-group) {
    width: 218px;
    border-radius: 4px;
    border-color: var(--gray-200);
    input {
      height: 30px;
      background-color: var(--blue-100);
      font-size: 12px;
      line-height: 20px;
    }
  }
}
