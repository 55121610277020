.testingForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    width: 100%;
  }
  h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
}
