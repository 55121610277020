.cmp-iframe {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;

  &__iframe {
    display: block;
    height: 150%;
    width: 150%;
  }
}
