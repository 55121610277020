.datasetSelect {
  &__radioButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 0;
    margin-bottom: 4px;
  }
  &__textarea {
    width: 100%;
    textarea {
      min-height: 120px;
      background: white;
    }
    :global(.textarea-container) {
      width: 100%;
    }
  }
  &__hidden {
    display: none !important;
  }
}
