.recordTestWidget {
  &__submitButton {
    grid-area: submit;
    width: 100%;
    &-fullScreen {
      @extend .recordTestWidget__submitButton;
      max-width: 341px;
      align-self: center;
    }
    &:global(.button-cmp.disabled) {
      background-color: var(--gray-300);
      border-color: var(--gray-300);
      color: var(--gray-500);
    }
  }
  &__content {
    display: flex;
    height: 450px;
    width: 100%;
    background-color: white;
  }
}
