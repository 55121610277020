.testingForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    width: 100%;
  }
}
