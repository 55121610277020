.configurationSelect {
  &__textarea {
    width: 100%;
    textarea {
      min-height: 120px;
      background: white;
    }
    :global(.textarea-container) {
      width: 100%;
    }
  }
  &__select {
    input {
      background-color: white !important;
      &:hover {
        background-color: white !important;
      }
    }
  }
  &__hidden {
    display: none !important;
  }
}
