@use 'scss/mixins/custom';

.markdown-container {
  color: var(--gray-800);
  line-height: 20px;

  &__link {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    gap: 8px;
    color: var(--indigo);

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & p {
    margin-bottom: 16px;
    width: 100%;
  }
  & h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  & h2 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  & h3 {
    margin-top: 18px;
    margin-bottom: 16px;
  }
  & h4 {
    margin-top: 6px;
    margin-bottom: 12px;
  }
  & pre,
  code {
    white-space: pre-wrap !important;
    word-break: break-word !important;
  }

  & table {
    margin: 16px 0;
    width: 100%;
    table-layout: fixed;

    border-collapse: unset;

    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border: 1px solid var(--blue-200);

    & tr th {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    & tr {
      border-top: 1px solid var(--blue-200);
    }

    & tr th {
      border-bottom: 1px solid var(--blue-200);
    }
    & tr td {
      padding: 13px 6px;
      border-bottom: 1px solid var(--blue-200);
    }
    & th td {
      padding: 13px 6px;
      border-bottom: 1px solid var(--blue-200);
    }
    & tr:first-child {
      border-top: none;
    }
    & tr:last-child td {
      border-bottom: none;
    }
    & tr th:first-child {
      padding-left: 24px;
    }
    & tr th:last-child {
      padding-right: 24px;
    }
    & tr td:first-child {
      padding-left: 24px;
    }
    & tr td:last-child {
      padding-right: 24px;
    }

    & td {
      padding: 0 5px;
    }

    & code {
      word-wrap: anywhere;
    }
  }
}
