.cmp-simpleRadioButtons {
  position: relative;
  width: 100%;
  white-space: nowrap;

  &__container {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    label.justui_input-radioButton-container {
      padding-right: 24px;
    }
  }
}
