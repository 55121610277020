.iconSelectList {
  position: absolute;
  transform: translateY(3px);
  background-color: #f9f9f9;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%), 0 6px 12px rgb(12 43 66 / 16%), 0 0 1px rgb(17 60 92 / 6%);
  padding: 3px 0;
  z-index: 750;
  display: flex;
  flex-direction: column;
  &_fixed {
    position: fixed;
  }
  &_absolute {
    position: absolute;
  }
  &__item {
    display: flex;
    padding: 6px 10px;
    line-height: 24px;
    border: 1px solid transparent;
    transition: background-color 0.15s;
    &:hover {
      background-color: var(--primary-300);
    }
  }
}
