.catalogList {
  &__container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-basis: 75%;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    max-width: 1215px;
    width: 100%;
    row-gap: 24px;
    column-gap: 2%;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
      justify-items: center;
      > * {
        min-width: 100%;
      }
    }
  }

  &__cardLink {
    display: contents;
  }
  &__notFound {
    margin-top: 40px;
  }
}
