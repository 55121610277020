.trainingWidgetHeader {
  display: flex;
  gap: 8px;
  margin-bottom: 9px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  span {
    font-size: 12px;
  }
  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
