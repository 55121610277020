@use 'scss/mixins/custom';

.messages {
  &__content {
    position: relative;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    @include custom.scrollbar;

    height: 450px;
    width: 100%;
    background-color: white;
  }
}
