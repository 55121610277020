.tester {
  &__wrapper {
    min-width: 397px;
    max-width: 397px;
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    &--fullScreen {
      @extend .tester__wrapper;
      position: fixed;
      overflow: auto;
      z-index: 760;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0;
      padding-top: 36px;
      max-width: unset;
      background: white;
      display: flex;
      align-items: center;
    }
  }
  &__content {
    border-radius: 8px;
    background-color: var(--blue-100);
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;
    max-width: 688px;
    box-sizing: content-box;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    > * {
      width: 100%;
    }
  }
  &__title {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  &__submitButton {
    grid-area: submit;
    width: 100%;
    &-fullScreen {
      @extend .tester__submitButton;
      max-width: 295px;
      align-self: center;
    }
    &:global(.button-cmp.disabled) {
      background-color: var(--gray-300);
      border-color: var(--gray-300);
      color: var(--gray-500);
    }
  }
  &__serviceName--fullScreen {
    margin-bottom: 30px;
  }
  &__header--fullScreen {
    display: flex;
    max-width: 688px;
    width: 100%;
    justify-content: space-between;
  }
}
