.catalogItem {
  &__container {
    border-radius: 6px;
    box-sizing: inherit;
    min-width: inherit;
    padding: 16px;
    border: 1px solid #d6e2f2;
    background: white;
    min-height: 196px;
    width: 100%;
    // max-height: 235px;
    max-width: 389px;
    display: grid;
    grid-template-rows: min-content min-content auto min-content;
    grid-template-areas:
      'title'
      'description'
      'tags'
      'footer';
    gap: 12px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 2px 8px 0px #0c2b421f, 0px 0px 2px 0px #0000001a;
      .name {
        color: var(--primary-600);
      }
    }
  }
  &__text {
    grid-area: description;
    color: var(--gray-800);
    p {
      max-lines: 3;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}
