.tester {
  &__container {
    width: 100%;
    max-height: 338px;
    background-color: var(--blue-100);
    padding: 20px 24px;
    border-radius: 8px;
    display: grid;
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas:
      'title'
      'header'
      'error'
      'textarea'
      'submit';
  }
  &__title {
    grid-area: title;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  &__header {
    grid-area: header;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 4px;
    font-weight: 400;
    color: var(--gray-600);
    font-size: 12px;
    margin-bottom: 12px;
    & svg {
      margin-right: 9px;
    }
  }
  &__error-message {
    color: var(--red-600);
    margin-bottom: 12px;
  }
  &__textarea-container {
    grid-area: textarea;
    background-color: white;
    padding: 8px 12px 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    margin-bottom: 12px;

    &.error {
      border: 1px solid var(--red-600);
    }
  }
  &__textarea {
    border: none;
    outline: none;
    resize: none;
    overflow: auto;
    line-height: 24px;
    width: 100%;
    padding: 0;
  }
  &__textarea-counter {
    margin-top: 4px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: var(--gray-400);
    font-size: 12px;
    line-height: 16px;
    &.warn {
      color: var(--yellow-400);
    }
    &.danger {
      color: var(--red-500);
    }
  }
  &__submit-button {
    grid-area: submit;
    width: 100%;
  }
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8bff6;
    border-radius: 6px;
  }
}

.json-view {
  white-space: break-spaces;
  word-break: break-word;
}

.captcha {
  transform: none !important;
  * {
    transform: none !important;
  }
}
