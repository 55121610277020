.progressBar {
  &__container {
    position: relative;
    grid-area: submit;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background-color: var(--indigo-200);
  }
  &__progress {
    background-color: var(--indigo-400);
    height: 100%;
    border-radius: inherit;
    transition: all 1.5s;
  }
  &__title {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: var(--indigo-800);
  }
}
