.editRequestBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
  &__radioButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  &__textarea {
    width: 100%;
    textarea {
      min-height: 120px;
      background: white;
    }
    :global(.textarea-container) {
      width: 100%;
    }
  }
}
