.message {
  &__wrap {
    margin-top: 12px;
    padding: 0 16px;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;

    &_CLIENTMESSAGE {
      justify-content: flex-end;
      .message__container {
        background-color: var(--blue-100) !important;
        color: var(--blue-300) !important;
        border-color: var(--blue-300) !important;
        &__name {
          text-align: left;
        }
        .message__text {
          color: var(--blue-600) !important;
        }
      }
    }

    &_BOTMESSAGE {
      .message__container {
        background-color: var(--gray-100) !important;
        color: var(--gray-300) !important;
        border-color: var(--gray-300) !important;
      }
    }
  }

  &__container {
    border: 1px solid transparent;
    max-width: 90%;
    padding: 8px 12px;
    background-color: var(--white);
    display: grid;
    grid-template-columns: 12px min-content;
    grid-template-rows: min-content min-content;
    grid-template: 'name name' 'text date';
    gap: 4px;
    border-radius: 0.5rem;
    animation: messageAppearance;
    animation-duration: 300ms;
    transform: translateY(0);
    opacity: 1;
  }
  // &__containerWide {
  //   @extend .message__container;
  //   max-width: calc(100% - 30px - 11px);
  // }

  &__text {
    grid-area: text;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    color: var(--gray-950);
    font-size: 14px;
    line-height: 21px;
    word-break: break-word;
    white-space: pre-wrap;
    overflow: auto;
  }
  &__date {
    grid-area: date;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    span {
      color: var(--gray-400);
      font-size: 12px;
      line-height: 18px;
    }
  }
}

@keyframes messageAppearance {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  20% {
    transform: translateY(-4px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes avatarAppear {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes messageAppearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
