@use 'scss/mixins/custom';
.cmp-textarea {
  padding-left: 2px;
  width: 100%;

  & textarea {
    @include custom.scrollbar__console;
    margin-top: 8px;
    background: var(--blue-100);
    border-radius: 6px;
    border: 1px solid var(--blue-200);
    padding: 6px 50px 6px 12px;
    &:focus {
      box-shadow: 0 0 0 2px rgba(96, 95, 227, 0.3) !important;
    }
    &:hover {
      border: 1px solid var(--blue-200);
    }
    ::placeholder {
      color: var(--gray-500);
    }
  }
}
