@use 'scss/mixins/forms';

.widgetLayout {
  &__wrapper {
    &_fullScreen {
      @extend .widgetLayout__wrapper;
      position: fixed;
      overflow: auto;
      z-index: 760;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0;
      padding-top: 36px;
      background: white;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@include forms.testingFormLayout(widgetLayout__wrapper, widgetLayout__content);

button.widgetLayout__toggle {
  display: flex;
  position: absolute !important;
  top: 24px;
  right: 24px;
  width: min-content;
}
