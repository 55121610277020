.configurationEdit {
  display: flex;
  gap: 8px;
  justify-content: flex-start;

  &__hidden {
    display: none !important;
  }

  &__textarea {
    width: 100%;
    textarea {
      min-height: 120px;
      background: white;
    }
    :global(.textarea-container) {
      width: 100%;
    }
  }
  &__select {
    input {
      background-color: white !important;
      &:hover {
        background-color: white !important;
      }
    }
  }
  &__selectWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__button_edit {
    margin: 0;
    border-radius: 8px;
    color: var(--gray-800);
    height: min-content;
    background-color: white;
  }

  &__row {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  &__content {
    margin-top: 4px;
  }

  &__textareaContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__error {
    color: var(--red-500);
  }
}
