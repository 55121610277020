.datasetSelect {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  &__input {
    background: white !important;
  }
  &__uploadRequirements {
    font-size: 12px !important;
    line-height: 18px;
    color: var(--gray-600);
  }
  &__pseudoLink {
    color: var(--primary);
    border-bottom: 1px dashed var(--primary);
    cursor: pointer;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  &__radioButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 0;
  }
  &__textarea {
    width: 100%;
    textarea {
      min-height: 120px;
      background: white;
    }
    :global(.textarea-container) {
      width: 100%;
    }
  }
  &__select {
    input {
      background-color: white !important;
      &:hover {
        background-color: white !important;
      }
    }
  }
  &__hidden {
    display: none !important;
  }
}
