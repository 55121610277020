.trainingResult {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__button {
    width: 100%;
  }
  &__button-fullScreen {
    width: 100%;
    max-width: 341px;
    align-self: center;
  }
  &__button_reset {
    background-color: white;
  }
}
