.markdownContainer {
  &__navigation {
    position: sticky;
    top: 0;
    background-color: white;
    & :global(.nav-link) {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }
}
