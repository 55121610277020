.status {
  &__container {
    display: flex;
    gap: 10px;
    align-items: center;
    width: min-content;
  }
  &__indicator {
    display: flex;
    flex-shrink: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: var(--gray-500);
  }
  &__label {
    background: transparent !important;
  }
  &__success {
    background: var(--success);
    color: var(--success);
  }
  &__info {
    background: var(--info);
    color: var(--info);
  }
  &__danger {
    background: var(--danger);
    color: var(--danger);
  }
  &__secondary {
    background: var(--gray-500);
    color: var(--gray-500);
  }
  &__warning {
    background: var(--warning);
    color: var(--warning);
  }
}
