.balance {
  &__chip {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 8px 12px;
    line-height: 20px;
    color: var(--primary-700);
    border-radius: 6px;
    transition: background-color 0.15s;
    &:hover {
      cursor: pointer;
    }
    .balance__overallBalance {
      color: var(--gray-800);
    }
    &-active {
      @extend .balance__chip;
      background-color: var(--primary-100);
      .balance__overallBalance {
        color: var(--primary-700) !important;
      }
    }
  }

  &__dropdownContent {
    min-width: 242px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0px;
    button {
      margin-right: 5px !important;
      margin-left: 5px !important;
    }
  }
  &__dropdownContentItem {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0px 20px;

    &-colored {
      @extend .balance__dropdownContentItem;
      background-color: var(--blue-100);
      padding-left: 36px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    small {
      color: var(--gray-500);
    }
  }
}
