.toggleFullScreen {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--indigo-600);
  font-size: 12px;
  font-weight: normal;
  gap: 5px;
  cursor: pointer;
  padding: 0px 3px;
  margin: 0;
  :global(.justui__icon-wrapper) {
    display: flex;
  }
  span {
    line-height: 20px;
  }

  &:active {
    background: var(--indigo-300);
  }
}
