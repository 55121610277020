.header {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 8px;
  &__accountChip {
    background-color: transparent;
    border-color: transparent;
    color: var(--gray-500);
    font-size: 14px;
    p {
      font-size: 14px;
    }
  }
  h1 {
    color: var(--gray-800);
    word-break: break-all;
    text-wrap: pretty;
  }
  &__description {
    color: var(--gray-800);
    margin-top: 12px;
  }
  &__infoPanel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__favoriteButton {
    color: var(--primary);
    display: flex;
    padding: 0 !important;
    margin: 0;
    width: 100%;
    gap: 8px;
    transition: color 0.15s;
    :global(.justui__icon-wrapper) {
      display: flex;
    }
    &:hover {
      color: var(--primary-700);
    }
    &:active {
      color: var(--primary-800);
    }
    &:before {
      border: 0 !important;
    }
    &:global(.button-cmp) {
      &:before {
        $size: 16px;
        top: -$size/2;
        left: -$size/2;
        width: calc(100% + $size);
        height: calc(100% + $size);
      }
    }
  }
  &__favoriteIcon {
    :global(.justui__icon-wrapper) {
      display: flex;
      color: var(--primary);
    }
  }
}
