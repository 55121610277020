.configurationSelect {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;

  &__label {
    font-size: 12px;
  }
  &__toggle {
    display: flex;
  }
}
