.catalogListPage {
  &__container {
    display: flex;
    height: fit-content;
    padding: 28px 16px;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 1280px;
  }
  &__filterContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }
  &__headSection {
    margin-bottom: 16px;
  }
  &__cardLink {
    display: contents;
  }
  &__content {
    position: relative;
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;
  }
  &__wrap {
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;
  }
  &__filtersContainer {
    display: flex;
    width: min-content;
    gap: 8px;
    display: flex;
    width: 100%;
    padding: 9px 16px;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 1px 3px 0px #0c2b420f, 0px 0px 1px 0px #00000029;
    margin-bottom: 21px;
    font-size: 12px;
    line-height: 16px;
  }
  &__filtersLabel {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    align-self: flex-start;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
