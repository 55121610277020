.token {
  &.curl {
    color: var(--red-500);
  }

  &.url {
    color: var(--red-600);
    background: inherit;
  }

  &.option {
  }

  &.value {
    color: var(--green-500);
  }
  &.token-placeholder {
    color: var(--red-600);
  }
}
