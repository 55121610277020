.testerResponse {
  &__responseHead {
    display: flex;
    > h3 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }
  }
  &__audio {
    display: flex;
    padding: 3px;
    background: white;
    border-radius: 6px;
    gap: 10px;
    align-items: center;
    width: 100%;
    :global(.justui__icon-wrapper) {
      display: flex;
    }
  }
  &__downloadButton {
    &:global(.button-cmp) {
      padding: 7px 8px 9px !important;
      margin: 0;
      color: var(--gray);
    }
  }
}
