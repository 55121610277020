.configurationSelect {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__spoiler {
    margin: 0;
    :global(.justui_spoiler-header-wrap) > :global(.justui_spoiler-header) {
      align-items: center !important;
    }
    :global(.justui_spoiler-collapse) {
      width: 100% !important;
    }
    :global(.justui_spoiler-header-wrap) {
      padding-top: 3px;
    }
    :global(.justui__icon-wrapper) {
      display: flex;
    }
    &_noMargin {
      margin: 0;
    }
  }
  &__wrap,
  &__spoiler {
    margin-bottom: 16px;
    h3 {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
      font-weight: 700;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
}
