.carousel {
  display: flex;
  position: relative;
  max-width: 1247px;
  width: 100%;
  gap: 20px;
  min-height: 324px;
  padding: 16px;
  background: var(--blue-100);
  border-radius: 8px;
  flex-direction: column;

  &__wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
  }
  &__container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: auto;
    flex-wrap: nowrap;
    gap: 2%;
    transition: transform 0.5s ease;
    > * {
      min-width: 32%;
      flex: 0 0 32%;
      @media (max-width: 991px) {
        min-width: 49%;
        flex: 0 0 49%;
      }
      @media (max-width: 575px) {
        min-width: 100%;
        flex: 0 0 100%;
      }
    }
    @media (max-width: 575px) {
      gap: 0px;
    }
    width: 100% !important;
  }

  &__buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &__button {
    background: white;
    padding: 8px;
    & :global(.justui__icon-wrapper) {
      display: flex;
    }
    box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 2px 8px 0px #0c2b421f, 0px 0px 2px 0px #0000001a;
    &:hover,
    :active,
    :focus {
      box-shadow: 0px 0px 1px 0px #113c5c0f, 0px 2px 8px 0px #0c2b421f, 0px 0px 2px 0px #0000001a !important;
    }
    &:global(.button-cmp.disabled) {
      background-color: white;
      svg {
        color: var(--gray-300) !important;
      }
    }
  }
}
