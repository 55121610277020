.select {
  border-radius: 6px;
  transition: border padding 0.2s;
  background-color: var(--blue-100);

  &__hidden {
    display: none !important;
  }

  :global(.just-select-container) {
    transition: box-shadow 0.2s;
    border: 1px solid var(--blue-200);
    border-radius: 6px;
    &:active {
      border: 1px solid var(--blue-200);
    }
    &:global(.focused) {
      //   border: 2px solid var(--indigo-500);
      border: none;
      padding: 1px;
      box-shadow: 0 0 0 2px rgba(96, 95, 227, 0.3);
      background-color: var(--white);
    }
  }
  input {
    transition: background-color 0.15s;
    background-color: var(--blue-100) !important;
    padding-right: 20px !important;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--gray-500);
    }
    &:hover {
      background-color: var(--blue-100) !important;
    }
    &:focus {
      background-color: var(--white) !important;
    }
  }
}
