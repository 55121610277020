@use 'scss/mixins/custom';

.loading__wrap {
  display: flex;
  padding: 5px 20px;
}

.loading__bubble {
  position: relative;
  animation: bubble-pulse 0.8s infinite linear alternate;
  animation-delay: 0.4s;
}
.loading__bubble::before,
.loading__bubble::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  animation: bubble-pulse 0.8s infinite alternate;
}
.loading__bubble,
.loading__bubble::before,
.loading__bubble::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  color: var(--gray-400);
  background-color: var(--gray-400);
}

.loading__bubble::before {
  left: -15px;
  animation-delay: 0s;
}
.loading__bubble::after {
  left: 15px;
  animation-delay: 0.8s;
}

@keyframes bubble-pulse {
  0% {
    background-color: var(--gray-400);
  }
  50%,
  100% {
    background-color: var(--gray-200);
  }
}
