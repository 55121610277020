@use 'scss/mixins/forms';

.widgetLayout {
  &__wrapper {
    &_fullScreen {
      @extend .widgetLayout__wrapper;
      max-width: 1280px !important;
      .widgetLayout__content {
        max-width: unset !important;
      }
    }
  }
}
@include forms.testingFormLayout(widgetLayout__wrapper, widgetLayout__content);
