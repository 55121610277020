.sendMessage {
  display: flex;
  position: relative;
  gap: 5px;
  background-color: white;
  align-items: center;
  > :global(.cmp-textarea) {
    textarea:focus {
      box-shadow: none !important;
    }
  }
  &__text {
    textarea,
    textarea::after {
      background-color: transparent !important;
      border-radius: 0 !important;
      border: none !important;
      box-shadow: none !important;
      padding-left: 16px !important;
      margin-top: 10px !important;
    }
  }
  &__control {
    display: flex;
    align-self: flex-start;
    margin-top: 16px;
    margin-right: 16px;
    height: 100%;
    width: fit-content;
  }
  &__button {
    display: flex;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 40px;
    min-width: fit-content;
    &:global(.button-cmp.disabled) {
      border-color: var(--gray-100);
      background-color: var(--gray-100);
      svg {
        color: var(--gray-300);
      }
    }
  }
}
